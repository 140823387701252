export default {
    state: () => ({
        'testKey' : 'test core value',
    }),
    mutations: {
        generic (state, payload) {

            if (typeof payload !== 'object') {
                return false;
            }

            if (typeof payload.key !== 'string') {
                return false;
            }

            if (typeof payload.value === 'undefined') {
                return false;
            }

            state[payload.key] = payload.value;

            return true;

        }
    },
    actions: {

    },
    getters: {
        generic: (state) => (key) => {

            if (typeof state[key] === 'undefined') {
                return null;
            } else {
                return state[key];
            }

        }
    }

    // TODO: with strict mode, this bugs because async comopnent data is stored here, which component may modify.
};
