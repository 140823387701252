const cookies = require('js-cookie');
import _ from 'lodash/object';

const plugin =  {
    that :this,
    defaultConfig : {
        expires : Number(config.cookie.expires),
        path    : config.cookie.path
    },
    install(target, options = {}) {
        let  { set, get, remove, defaultConfig} = plugin;
        plugin.defaultConfig = _.merge(defaultConfig, options);

        target.$cookie = {set, get, remove, defaultConfig};
    },
    set (key, value, config = plugin.defaultConfig) {
        return cookies.set(key, value, config);
    },
    get (key, defaultValue = null) {
        let val =  cookies.get(key);
        if (typeof val === 'undefined') {
            return defaultValue;
        }

        return val;
    },
    remove (name) {
        return cookies.remove(name);
    },
    delete (name) {
        return cookies.remove(name);
    }
};




let assignCookieToModuleRecursive = (module) => {
    plugin.install(module, {});

    if (module._children) {
        for (const [index, childModule] of Object.entries(module._children)) {
            assignCookieToModuleRecursive(childModule);
        }
    }
};

export default (store) => {
    plugin.install(store, {});
    assignCookieToModuleRecursive(store._modules.root);
    //console.log(store._modules.root);
}
